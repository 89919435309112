import React from "react";
import styles from "./style.module.scss";

export default function Contacts() {
  return (
    <div className={`wrapper ${styles.container}`}>
      <div className={styles.main}>
        <div className={styles.content}>
          <h2>Контактная информация</h2>
          <h3>Компания ARM KOVKA</h3>
          <div className={styles.contactsMain}>
            <div className={styles.tels}>
              <span>
                Тел: <a href="tel:+79309419397">+7(930) 941-93-97</a>
              </span>
              <span>
                <a href="tel:+74957828190">+7(495) 782-81-90</a>
              </span>
              <span>
                <a href="tel:+79637129406">+7(963) 712-94-06</a>
              </span>
            </div>
            <div className={styles.mails}>
              <span>
                <a href="mailto:armen6828@mail.ru">armen6828@mail.ru</a>
              </span>
            </div>
          </div>
          <div className={styles.btns}>
            <a target="_blank" href="https://wa.me/79637129406">
              <span>Whatsapp</span>
            </a>
            <a target="_blank" href="https://t.me/Armen_1968">
              <span>Telegram</span>
            </a>
          </div>
        </div>
        <div className={styles.map}>
          <iframe
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A0096d61c7bce260601498cc852470afeebdc407054f0a68b66134c30e41afc4a&amp;source=constructor"
            frameborder="0"
            allowfullscreen="true"
            width="100%"
            height="100%"
            style={{ display: "block", minHeight: "500px" }}
          ></iframe>
        </div>
      </div>
      <div className="line" />
    </div>
  );
}
